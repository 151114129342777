@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';
@import 'libs/html-grid';
@import 'fontawesome-all.min.css';


	@include breakpoints((
		xlarge:   ( 1281px,  1680px ),
		large:    ( 981px,   1280px ),
		medium:   ( 737px,   980px  ),
		small:    ( 481px,   736px  ),
		xsmall:   ( null,    480px  )
	));

// Mixins.

	@mixin color($p) {
		@include color-typography($p);
		@include color-box($p);
		@include color-button($p);
		@include color-form($p);
		@include color-list($p);
		@include color-menu($p);
		@include color-alt-icons($p);
		@include color-table($p);
		@include color-split($p);
		@include color-spotlights($p);
		@include color-features($p);
	}

// Base.

	@import 'base/reset';
	@import 'base/page';
	@import 'base/typography';

// Component.

	@import 'components/row';
	@import 'components/box';
	@import 'components/button';
	@import 'components/form';
	@import 'components/icon';
	@import 'components/image';
	@import 'components/list';
	@import 'components/actions';
	@import 'components/icons';
	@import 'components/menu';
	@import 'components/alt-icons';
	@import 'components/section';
	@import 'components/table';
	@import 'components/split';
	@import 'components/spotlights';
	@import 'components/features';
	@import 'components/wrapper';

// Layout.

	@import 'layout/header';
	@import 'layout/menu';
	@import 'layout/banner';
	@import 'layout/main';
	@import 'layout/footer';

// Custom.
	@import 'custom';
